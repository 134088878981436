import React from 'react';
import { Header, Container } from 'semantic-ui-react';
import { graphql } from "gatsby";
import unified from 'unified';
import markdown from 'remark-parse';
import html from 'remark-html';

import Layout from "../components/layout";
import '../style/happening-page.css';

export default ({ data }) => {
  const { airtable } = data;
  const { data: happeningsData } = airtable;

  return (
    <Layout>
      <Container className="happening-page-container">
        <Header as="h1">{happeningsData.Title}</Header>
        <span className="happening-date">{happeningsData.Date}</span>
        <Header as="h6" className="happening-author">Written By {happeningsData.Author}</Header>
        <div
          className="happening-hero-image"
          style={{ backgroundImage: `url('${happeningsData.HeroImageURL}')`}}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: unified()
              .use(markdown)
              .use(html)
              .processSync(happeningsData.PostMarkdown)
          }}
        />
      </Container>
    </Layout>
  )
};

export const query = graphql`
  query($title: String!) {
    airtable(table: {eq: "Happenings"}, data: {Title: {eq: $title}}) {
      data {
        Title
        Date
        Author
        PostMarkdown
        HeroImage {
          url
        }
        HeroImageURL
        HappeningType
      }
    }
  }
`;